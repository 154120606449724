import ArticleBuilder from "../components/article-builder/ArticleBuilder"
import ArticleHeader from "../components/article-header/ArticleHeader"
import Footer from "../components/footer/Footer"
import Layout from "../components/layout/Layout"
import React from "react"
import SEO from "../components/seo/Seo"
import getArticleSEO from "../utils/getArticleSEO"
import { graphql } from "gatsby"

const FeatureArticle = ({ data }) => {
  const article = data.featureArticle
  const articleSEO = getArticleSEO(article)

  return (
    <Layout overlay={article.menuTheme}>
      <SEO
        title={articleSEO.title}
        description={articleSEO.description}
        image={articleSEO.image}
        article={true}
      />
      <article>
        <ArticleBuilder sections={article._rawFeatureTopContent} />
        <ArticleHeader
          title={article.articleTitle}
          published={article._updatedAt}
          credit={article.articleCredits}
          media={
            article.imageCover && article.showImageCover === true
              ? article.imageCover
              : null
          }
        />
        <ArticleBuilder sections={article._rawFeatureContent} />
        <Footer />
      </article>
    </Layout>
  )
}

export const query = graphql`
  query FeatureArticleQuery($id: String!) {
    featureArticle: sanityArticleFeature(id: { eq: $id }) {
      ...FeatureArticle
    }
  }
`

export default FeatureArticle
